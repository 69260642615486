import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AccountService } from './configuration-management/services/account.service';
import { Router } from '@angular/router';
import { User } from './configuration-management/models/user.interface';
import { SyncService } from './shared/offline/sync-service';
import { UserIdleService } from 'angular-user-idle';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from './layout/landing-page/login-dialog/login.dialog';
import { Subscription } from 'rxjs';
import { ConfigLoaderService } from './config-loader.service';
import { storage } from './shared/local-storage/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUser: User;
  events: string[] = [];
  opened = true;
  shouldRun = true;
  subscription = Subscription;
  syncFinished = false;

  constructor(
    public swUpdate: SwUpdate,
    private accountService: AccountService,
    private router: Router,
    private syncService: SyncService,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
    public configService: ConfigLoaderService,
  ) {

    this.accountService.currentUser.subscribe(x => this.currentUser = x);
    const showLogin = storage.getItem<boolean>('show-login', false);

    if (showLogin === true) {
      this.showLoginForm();
    }

    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => console.log(count));
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => this.showLoginForm());


    if (this.currentUser != null) {
      if (this.currentUser.keepLoggedIn === true) {
        this.userIdle.stopWatching();
        this.userIdle.stopTimer();
      }
    }


  }

  ngOnInit() {

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('A newer version of the application is available. Load the new version ?')) {
          window.location.reload();
        }
      });
    }

  }


  showLoginForm() {


    storage.setItem('show-login', true);
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      disableClose: true,
      height: 'auto',
      width: 'auto',
      panelClass: 'login-panel'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (this.currentUser.keepLoggedIn === true) {
        this.userIdle.stopWatching();
        this.userIdle.stopTimer();
      }
      else {
        this.userIdle.resetTimer();
      }
    });

  }



  logout() {
    this.accountService.logout();
    this.router.navigateByUrl('login');
  }

}
