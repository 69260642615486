import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { ClientSummaryListViewModel, ClientSummarySync, Page, Client } from '../../client-management/models/client.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { IndexDetails } from 'ngx-indexed-db';
import { from, Subscription, timer } from 'rxjs';
import { LineItem, LineItemSubmission, Drafts } from '../../line-item-entry/models/line-item.interface';
import { dbContext } from './offline-db.service';
import { LocationSummary } from 'src/app/configuration-management/models/locations.interface';
import { UserAccount } from '../../configuration-management/models/user.interface';
import { DiscountSummary } from '../../configuration-management/models/discounts.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from 'src/app/helpers/offline-detection';
import { Patient } from '../../patient-management/models/patient.interface';
import { EmailViewModel, ClientEmailDraft } from '../../configuration-management/models/email.interface';
import { interval } from 'rxjs';
import { now } from 'moment';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { ConfigLoaderService } from 'src/app/config-loader.service';

export class SyncRequestBody {
    syncLineItems: number;
    syncInventoryLocations: number;
    syncDoctors: number;
    syncDiscounts: number;
    syncClientsAndPatientSummary: number;
    syncPatients: number;
    syncMedicalNoteTemplates: number;
    lastSyncClientNumber: number;
    lastSyncLineItems: string;
    lastSyncInventoryLocations: string;
    lastSyncDoctors: string;
    lastSyncDiscounts: string;
    lastSyncClientsAndPatientSummary: string;
    lastSyncPatients: string;
    lastSyncMedicalNoteTemplates: string;
    lastSyncPatientNumber: number;
}

export class SyncResponse {
    syncLineItems: LineItem[];
    syncInventoryLocations: LocationSummary[];
    syncDoctors: UserAccount[];
    syncDiscounts: DiscountSummary[];
    syncClientsAndPatientSummary: Client[];
    syncPatients: Patient[];
    syncMedicalNoteTemplates: EmailViewModel[];
    clientNumberLastUpdated: number;
    lastRecordsReturned: string;
    lineItemsLastUpdated: string;
    clientPatientsLastUpdated: string;
    inventoryLocationsLastUpdated: string;
    doctorsLastUpdated: string;
    discountsLastUpdated: string;
    patientsLastUpdated: string;
    patientNumberLastUpdated: number;
    medicalTemplatesLastUpdated: string;
}

@Injectable({
    providedIn: 'root'
})
export class SyncService extends BaseService {

    clients: ClientSummaryListViewModel;
    client: Client;
    constructor(
        public snackBar: MatSnackBar,
        http: HttpClient,
        configService: ConfigLoaderService,
        connectionService: ConnectionService) {
        super(http, connectionService, configService);
    }

    clear() {
      dbContext.lineItems.clear();
      dbContext.inventoryLocations.clear();
      dbContext.doctors.clear();
      dbContext.discounts.clear();
      dbContext.clientSearch.clear();
      dbContext.drafts.clear();
      dbContext.patients.clear();
      dbContext.emailTemplates.clear();
      dbContext.emailDraft.clear();
      localStorage.removeItem('tables-cleared-2024-05');
      localStorage.removeItem('client-number-last-updated');
      localStorage.removeItem('line-items-last-updated');
      localStorage.removeItem('inventory-locations-last-updated');
      localStorage.removeItem('doctors-last-updated');
      localStorage.removeItem('discounts-last-updated');
      localStorage.removeItem('clients-patients-last-updated');
      localStorage.removeItem('patients-last-updated');
      localStorage.removeItem('patients-last-updated-patient-number');
      localStorage.removeItem('medical-templates-last-updated');
    }
}
